<template>
    <!-- Section 3 -->
  <section   class="w-full bg-white pt-7 pb-7 md:pt-20 md:pb-24">
     <div class="top">
        <div class="box-border w-full flex flex-col items-center content-center  mb-8 px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row  lg:px-16">
 
 <!-- Content -->
 <div data-aos="fade-right-up" data-aos-duration="3000" class="w-full  box-border  w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-20">
     <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
      Personnaliser votre profil digital et partager vos informations 
         </h2>
     <p class="pt-4 ml-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
      <ol>
        <li> ➔ Bénéficiez d'un large choix d'options (Nom, logo, photo, email, téléphone, média sociaux et professionnels) </li>
        <li> ➔ Visualisez en direct vos modifications </li> <br>
        <li> ➔ Modifiez votre page en illimité 24/24 et 7j/7 </li>
        <li> ➔ Changez vos informations autant de fois que vous le souhaitez</li>
      </ol>
</p>       
 <div class="flex-one text-right  h-16 items-center mt-4">
        <a href="https://shop.tagango.com/"><CreateButton label="signIn"></CreateButton> </a>
     </div>
 </div>
 <!-- End  Content -->
 
 <!-- Image -->
 <div data-aos="fade-left-up" 
      data-aos-duration="3000" class="box-border relative w-full max-w-md mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-3/4 aos-init aos-animate">
     <img :src="images.proces" alt="app" class="" original>
 </div>
 </div>
 <div class="w-full  box-border flex flex-col items-center content-center mb-16 mt-20 px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row  lg:px-16">
 
 <!-- Image -->
 <div data-aos="fade-left-up" id="products"
      data-aos-duration="3000" class="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
 <video class=" p-2 pl-6 lg:w-609px" autoplay loop muted>
  <source src="../../assets/video/video3.mp4" type="video/mp4">
</video>
 </div>
 
 <!-- Content -->
 <div data-aos="fade-right-up" data-aos-duration="3000" class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
  <h1 class="m-0 text-2xl font-bold mb-4 border-0 text-blueNew1  border-gray-300 lg:text-3xl md:text-2xl">Tag’nGo MELANO</h1>
 <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-2xl md:text-2xl">
  La carte de visite <b>« Tag’ngo Melano » </b>  est une carte de visite chic et haut de gamme. 
 </h2>
 <p class="pt-4 pb-8 m-0 leading-7 text-justify text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-30 lg:text-lg">
  Son nom <b>"Melano"</b>  fait référence à sa couleur noire. Cette carte de visite est un choix parfait pour les professionnels qui cherchent à transmettre une image de sophistication, <b>d'élégance</b>  et de sérieux. </p>
  <div class="flex-one text-right  h-16 items-center mt-4">
    <a href="https://shop.tagango.com/"><CreateButton label="signIn"></CreateButton> </a>
 </div>
 </div>
 <!-- End  Content -->
 
 </div>
 <div class="box-border hope w-full flex flex-col items-center content-center mb-8 px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row  lg:px-16">
 
 <!-- Content -->
 <div data-aos="fade-right-up" data-aos-duration="3000" class="w-full  box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
  <h1 class="m-0 text-xl mb-4 text-blueNew1 font-bold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">Tag’nGo ASPRO</h1>
     <h2 class="m-0 text-xl font-semibold text-white leading-tight border-0 border-gray-300 lg:text-2xl md:text-2xl">
      La carte <b>« Tag’ngo Aspro »</b>  est une carte de visite élégante et épurée.
      </h2>
     <p class="pt-4 pb-8 m-0 leading-7 text-white border-0 text-justify border-gray-300 sm:pr-10 lg:text-lg">
      Cette carte de visite est un choix parfait pour ceux qui cherchent à transmettre une image de professionnalisme, de simplicité et de clarté. <br>
 </p>
        <div class="flex-one text-right  h-16 items-center mt-4">
          <a href="https://shop.tagango.com"><CreateButton label="signIn"></CreateButton> </a>
     </div>
 </div>
 <!-- End  Content -->
 
 <!-- Image -->
 <div data-aos="fade-left-up" 
      data-aos-duration="3000" class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
     <video class=" sm:pr-10 xl:pl-10 lg:w-609px" autoplay loop muted>
  <source src="../../assets/video/video4.mp4" type="video/mp4">
</video>
 </div>
 </div>
 <div class="w-full  box-border flex flex-col items-center content-center mb-16 mt-20 px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row  lg:px-16">
 
 <!-- Image -->
 <div data-aos="fade-left-up" id="products"
      data-aos-duration="3000" class="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
 <video class=" p-2 pl-6 lg:w-609px" autoplay loop muted>
  <source src="../../assets/video/video3.mp4" type="video/mp4">
</video>
 </div>
 
 <!-- Content -->
 <div data-aos="fade-right-up" data-aos-duration="3000" class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
  <h1 class="m-0 text-2xl font-bold mb-4 border-0 text-blueNew1  border-gray-300 lg:text-3xl md:text-2xl">Tag’nGo Corporate</h1>
 <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-2xl md:text-2xl">
  La carte de visite <b>« Tag’ngo Corpoarate  » </b>  est une carte de visite chic et haut de gamme pour les entreprises. 
 </h2>
 <p class="pt-4 pb-8 m-0 leading-7 text-justify text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-30 lg:text-lg">
  Son nom <b>"Corpoarate"</b>  fait référence à sa personn . Cette carte de visite est un choix parfait pour les professionnels qui cherchent à transmettre une image de sophistication, <b>d'élégance</b>  et de sérieux. </p>
  <div class="flex-one text-right  h-16 items-center mt-4">
   <router-link to="/formulaire"><CreateButton label="signIn1"></CreateButton></router-link>  
 </div>
 </div>
 <!-- End  Content -->
 
 </div>
     </div>
 </section>
 </template>
 
 <script>
 import CreateButton from '../CreateButton.vue'
 import proces from '../../assets/images/dash.webp'
 import process from '../../assets/images/a.png'
 import Processs from '../../assets/images/b.png'

 export default {
   name: 'Process',
   components: { CreateButton },
   data () {
     return {
       images: { proces, process, Processs }
 
     }
   },
   methods: {
    
    openForm() {
      document.getElementById("myForm").style.display = "block";
    },
  },
 }
 </script>
 
 <style lang="scss" scoped>
 @import "../../assets/styles/sass/variables";
 .parent-container {
   height: 100%;
   padding-top: 3rem;
   padding-bottom: 3rem
 }
 .hope{
  background-image: url(../../assets/images/g.jpg);
  text-decoration: #8bc141;
 }
 .hope
 .details-block {
   background-color: white;
   background-image: url(../../assets/images/main.webp);
   z-index: 9999;
   margin-bottom: 20rem !important;
   margin-left: 30%;
   width: auto;
   background-repeat:no-repeat;
   background-size:cover;
   background-position:center;
   padding-top: 4rem;
   //  box-shadow: rgba(163, 177, 198, 0.5) 2px 2px 4px 0px, rgb(255, 255, 255) -2px -2px 4px 0px;
   // -webkit-box-shadow: rgba(163, 177, 198, 0.5) 2px 2px 4px 0px, rgb(255, 255, 255) -2px -2px 4px 0px;
 }
 .create-account {
   background-color: #8bc141;
   color: white;
   // width: 40%;
 }
 .how-subtitle {
   color: white;
 }
 @media only screen and (max-width: 600px) {
   .details-block {
     margin-left: .8rem;
     width: 90%;
   }
 }
 .img-mask {
        -webkit-mask-image: url(../../assets/images/images-mask.png);
        mask-image: url(../../assets/images/images-mask.png);
        -webkit-mask-size: 70%;
        mask-size: 70%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
      }
 
 </style>
 